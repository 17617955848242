<template>
  <div class="login custom-bg">
    <div class="header">
      <div v-if="name" @click="goHome" class="active-name">{{name}}</div>
    </div>

    <div class="login-form">
      <van-form @submit="onSubmit" :show-error="false" ref="form">
        <div class="input-item">
          <div class="input-label">活动编号</div>
          <van-field
            name="activeId"
            v-model="activeId"
            placeholder="请输入活动编号"
            autocomplete="off"
            :rules="[{ required: true, message: '请输入活动编号' }]"
            class="test"
            center
            :border="false"
          />
        </div>
        <div class="input-item">
          <div class="input-label">手机号码</div>
          <van-field
            name="mobile"
            v-model="mobile"
            placeholder="请输入手机号码"
            autocomplete="off"
            :rules="[
              { required: true, message: '请输入手机号码' },
              { pattern, message: '手机号格式不正确' },
            ]"
            type="tel"
            class="test"
            center
            :border="false"
          >
            <template #button>
              <div class="sendCode" @click="sendCodeVV">
                {{ sec }}<span v-if="sec >= 0">s后重新获取</span>
              </div>
            </template>
          </van-field>
        </div>
        <div class="input-item">
          <div class="input-label">验证码</div>
          <van-field
            v-model="sms"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
            type="number"
            class="test"
            center
          />
        </div>
      </van-form>
    </div>

    <van-button
      :loading="isLoading"
      loading-type="spinner"
      block
      @click="onSubmit"
      class="btn"
      :class="isActive ? 'isActive' : ''"
      >登录</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      activeId: "",
      mobile: "",
      sms: "",
      sec: "获取验证码",
      clear: null,
      pattern:
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
      isClick: false,
      isLoading: false,
      name: ''
    };
  },
  created() {
    if (this.$route.query.id) {
      this.activeId = this.$route.query.id;
    }else if(localStorage.getItem('id')) {
      this.$request({
        url: this.$api.home,
        params: {
          activityId: localStorage.getItem('id')
        },
      }).then((res) => {
        this.details = res.item;
        localStorage.setItem("code", this.details.code);
        localStorage.setItem("name", this.details.name);
        this.activeId = this.details.code
        this.name = this.details.name
      });
    }

    this.name = localStorage.getItem('name') || ''
  },
  computed: {
    isActive() {
      let res = false;
      if (
        this.mobile.trim() &&
        this.sms.trim() &&
        this.pattern.test(this.mobile)
      ) {
        res = true;
      }
      return res;
    },
  },
  methods: {
    onSubmit() {
      this.validCodeVV(this.login);
    },
    sendCodeVV() {
      this.$refs.form.validate("mobile").then((res) => {
        if (this.sec !== "获取验证码" && this.sec !== "重新获取") return;

        if (this.isClick) return;

        this.isClick = true;

        this.$vv({
          url: this.$api.sendVerifyCodeVV,
          params: {
            phone: this.mobile,
            type: 2,
          },
        })
          .then((res) => {
            this.sec = 60;
            Toast.success("发送成功");
            this.clear = setInterval(this.countDown, 1000);
          })
          .catch((err) => {
            Toast(err.data.msg);
          });
      });
    },
    validCodeVV(_callback) {
      var _this = this;
      _this
        .$vv({
          url: _this.$api.validCodeVV,
          params: {
            phone: _this.mobile,
            vcode: _this.sms,
          },
        })
        .then((res) => {
          _callback();
        })
        .catch((err) => {
          Toast(err.data.msg);
        });
    },
    login() {
      let _this = this;
      _this
        .$request({
          url: _this.$api.login,
          method: "POST",
          data: {
            // activityId: _this.activeId,
            activityCode: _this.activeId,
            mobile: _this.mobile,
            code: _this.sms,
          },
        })
        .then((res) => {
          if (res.success) {
            if (res.item.url) {
              window.open(res.item.url);
            } else {
              localStorage.setItem("token", res.item.token);
              localStorage.setItem("tokenVV", res.item.vvToken);
              localStorage.setItem("companyId", res.item.companyId);
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.item.userInfo)
              );
              Toast.success("登录成功");
              // this.$router.replace("/activity?id=" + _this.activeId);
              localStorage.setItem("code", _this.activeId);
              this.$router.replace("/activity?id=" + res.item.userInfo.activityId);
            // }
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    countDown() {
      var _this = this;
      if (_this.sec > 0) {
        --_this.sec;
      } else {
        clearInterval(_this.clear);
        _this.sec = "重新获取";
        this.isClick = false;
      }
    },
    goHome() {
      let id = localStorage.getItem('id') || ''
      if(id) {
        this.$router.push('/?id='+ id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  // background-color: #f8f9fc;
  // overflow: auto;
  .header {
    padding: 30px 0;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }

  .login-form {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    width: 345px;
    background: #ffffff;
    box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);
    border-radius: 16px;
    padding: 30px 30px 46px 20px;
    box-sizing: border-box;

    .input-item {
      font-size: 16px;
      border-bottom: 1px solid #f3f4ff;
      margin-bottom: 20px;
      .input-label {
        color: #8b8fad;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .van-cell {
      padding: 14px 0;
      font-size: 16px;
    }
    ::v-deep .van-field__control {
      color: #3e4576;
    }

    .sendCode {
      text-align: right;
      font-size: 16px;
      color: #3476ff;
    }
  }

  .btn {
    margin: 20px auto;
    width: 345px;
    height: 44px;
    border-radius: 22px;
    background: #b2b5c8;
    font-size: 18px;
    color: #fff;
  }
  .isActive {
    background: #3476ff;
  }

  .active-name {
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<style>
input::-webkit-input-placeholder {
  color: #3e4576 !important;
}
input::-moz-placeholder {
  color: #3e4576 !important;
}
input:-ms-input-placeholder {
  color: #3e4576 !important;
}
</style>
