import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Activity from '../views/Activity.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity
  },
  {
    path: '/activityadmin',
    name: 'activityadmin',
    component: () => import('../views/ActivityAdmin.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/signinqr',
    name: 'signinqr',
    component: () => import('../views/SigninQr.vue')
  },
  {
    path: '/signinadmin',
    name: 'signinadmin',
    component: () => import('../views/SigninAdmin.vue')
  },
  {
    path: '/rest',
    name: 'rest',
    component: () => import('../views/Rest.vue')
  },
  {
    path: '/restqr',
    name: 'restqr',
    component: () => import('../views/RestQr.vue')
  },
  {
    path: '/restadmin',
    name: 'restadmin',
    component: () => import('../views/RestAdmin.vue')
  },
  {
    path: '/food',
    name: 'food',
    component: () => import('../views/Food.vue')
  },
  {
    path: '/foodqr',
    name: 'foodqr',
    component: () => import('../views/FoodQr.vue')
  },
  {
    path: '/foodadmin',
    name: 'foodadmin',
    component: () => import('../views/FoodAdmin.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/serviceqr',
    name: 'serviceqr',
    component: () => import('../views/ServiceQr.vue')
  },
  {
    path: '/serviceadmin',
    name: 'serviceadmin',
    component: () => import('../views/ServiceAdmin.vue')
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('../views/Goods.vue')
  },
  {
    path: '/goodsqr',
    name: 'goodsqr',
    component: () => import('../views/GoodsQr.vue')
  },
  {
    path: '/goodsadmin',
    name: 'goodsadmin',
    component: () => import('../views/GoodsAdmin.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
