<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.getEnum();
  },
  methods: {
    getEnum() {
      this.$request({
        url: this.$api.enum,
      }).then((res) => {
        localStorage.setItem("enums", JSON.stringify(res.item));
      });
    },
  },
};
</script>
