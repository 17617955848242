export default {
	// resourcesPath: 'http://192.168.17.59:8001/',	// api
	// root: 'http://192.168.17.50:8080',						// url
	// vvcom: 'vip.test.vv-che.com',
	// vvapi: 'https://apis.test.vv-che.com',
	resourcesPath: 'https://org-activity.vv-che.com/activity-erp/api/',	// api
	root: 'https://org-activity.vv-che.com',						// url
	vvcom: 'vip.vv-che.com',
	vvapi: 'https://apis.vv-che.com',
	//upload: 'https://org-activity.vv-che.com/activity-erp/api/attach/upload',						// url
	upload: 'http://outer.vv-che.com/activity-erp/api/activity/upload',						// url

	enum: '/enumConstant/findEnumConstant',	// 枚举缓存
	login: '/member/findByMobile',	// 登录
	autologin: '/member/findByLogin',	// 自动登录
	sendVerifyCode: 'shorMessage/sendVerifyCode',	// 发送短信验证码
	validCode: 'shorMessage/validCode',	// 短信验证

	// resourceUrl: 'https://org-activity.vv-che.com/activity-erp',		// 资源地址
	resourceUrl: 'http://vvtools.vv-che.com/activity',		// 资源地址
	home: '/activity/detailActivity',	//活动主页
	activityDetails: '/activity/findByActivityIdHomePage',	// 活动详情
	activityCommander: '/activityPeople/findByActivityIdCommander',	// 活动总指挥
	activityAssistant: '/activityPeople/findByActivityIdAssistant',	// 活动助理
	getCity: '/activity/findByCity', // 获取定位信息
	// getMsg: '/message/findByActivityId', // 获取活动通知
	getMsg: '/message/pageMessage', // 获取活动通知
	detailMessage: '/message/detailMessage', // 活动通知详情

	// 签到签退
	sign: '/activity/findByActivityId',	// 签到/签退页面
	saveSign: '/activity/saveSignOrSignOut',	// 保存签到、签退
	detailSign: '/sign/detailSign',	// 扫码查看签到/签退详情
	updateSignStatus: '/activityPeople/updateSignStatus',	// 组织者扫码确认之后修改签到签退状态
	
	// 住宿
	accommodation: '/activity/findByActivityIdAccommodation',	// 住宿页面、续住
	findByRetreatAccommodation: '/activity/findByRetreatAccommodation',	// 办理退房查询
	saveRest: '/activity/saveAccommodation',	// 保存住宿
	saveRestSubsidy: '/activity/saveAccommodationSubsidy',	// 保存住宿,补贴形式
	detailRest: '/enter/detailEnter',	// 扫码查看住宿详情
	updateAccommodationStatus: '/activityPeople/updateAccommodationStatus',	// 组织者确认

	// 餐饮
	restaurant: '/activity/findByActivityIdRestaurant',	// 餐饮页面
	saveRestaurant: '/activity/saveRestaurant',	// 保存餐饮
	saveRestaurantSubsidy: '/activity/saveRestaurantSubsidy',	// 保存参与，补贴方式
	detailMeal: '/meal/detailMeal',	// 餐饮详情
	updateRestaurantStatus: '/activityPeople/updateRestaurantStatus',	// 组织者确认

	// 活动物资
	material: '/activity/findByActivityIdMaterial',	// 物资页面
	saveMaterial: '/activity/saveMaterial',	// 保存
	detailMaterial: '/materialCache/detailMaterialCache',	// 物资详情
	updateMaterialStatus: '/activityPeople/updateMaterialStatus',	// 组织者确认
	findByActivityIdResources: '/activityResources/findByActivityIdResources',	// 组织者查看更多物资

	// 活动服务
	help: '/activity/findByActivityIdHelp',	// 服务页面
	saveHelp: '/activity/saveHelp',	// 保存服务
	detailHelp: '/keep/detailKeep',	// 服务详情
	updateHelpStatus: '/activityPeople/updateHelpStatus',	// 组织者确认

	// 管理员
	findByUserInfoAll: '/scanRecord/findByUserInfoAll',	// 扫码记录

	// vv接口
	weather: '/company/common/getWeatherByHttp',	// 城市天气
	company: '/company/company/getComDetailInfo',	// 组织单位
	sendVerifyCodeVV: '/sms/common/sms',	// 发送短信验证码
	validCodeVV: '/sms/common/sms/check',	// 短信验证
	vvToken: '/sso/login/wx',
	getCode: '/vvorder/wxAuth/wechatWhetherBindPhone',
}