import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    qrInfo: {},
  },
  getters: {
  },
  mutations: {
    SET_QRINFO(state, payload) {
      state.qrInfo = payload
    },
    RESET_QRINFO(state, payload) {

    }
  },
  actions: {
  },
  modules: {
  }
})
