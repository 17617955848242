import axios from 'axios'
import qs from 'qs'
import api from '@/utils/api'
import {getToken} from '@/utils/localStorage'
import store from '@/store'
import router from '@/router'
import { Toast } from 'vant';

const request = axios.create({
    baseURL: api.resourcesPath,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
})

request.interceptors.request.use(config => {
    if (getToken()) { 
        config.headers['x-access-token'] = getToken().split("_")[0] + "_ms"
        // config.headers['x-access-token'] = getToken()
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // config.data = qs.stringify(config.data)
        config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    const {status, data} = response
    if (status >= 200 && status < 300) {
        if (data.success) {
            return response.data
        } else if (data.msg === 'no_user') {
            localStorage.removeItem('token')
            let id = localStorage.getItem('code') || ''
            router.replace('/login?id='+id).catch(err => { console.log(err) })

            return Promise.reject(response)
        } else if( data.msg.indexOf('用户信息') >= 0 || data.msg.indexOf('未在该活动') >= 0) {
            Toast(data.msg)
            let id = localStorage.getItem('code') || ''
            router.replace('/login?id='+id).catch(err => { console.log(err) })
            return Promise.reject(response)
        } else if (data.msg) {
            Toast(data.msg)
            // localStorage.removeItem('token')
            // let id = localStorage.getItem('id')
            // router.replace('/login?id='+id)
            return Promise.reject(response)
        } else {
            
            return Promise.reject(response)
        }
    }
    // 对响应数据做点什么
    return response;
}, function (error) {
    switch (error.response.status) {
        case 500:
            Toast('系统繁忙')
            break;
        // default:
        //     Notification.error(error.response.status + "")
        //     break;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default request
